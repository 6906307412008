<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="3">
        <v-select
          v-model="dateSelected"
          :items="dateOptions"
          label="Date Range"
          dense
          solo
          @change="fetchData"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(stat, index) in stats"
        :key="index"
        cols="12"
        sm="6"
        lg="4"
      >
        <base-material-stats-card
          color="info"
          v-bind="stat"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'DashboardDashboard',
    data: () => ({
      stats: [
        {
          title: 'Calls/Spending Requests',
          icon: 'mdi-phone',
          value: 0,
          loading: true,
        },
        {
          title: 'Budget Increase',
          icon: 'mdi-currency-usd',
          value: 0,
          loading: true,
        },
        {
          title: 'Campaign Paused',
          icon: 'mdi-pause',
          value: 0,
          loading: true,
        },
        {
          title: 'Number of Users',
          icon: 'mdi-account',
          value: 0,
          loading: true,
        },
        {
          title: 'Budget Recommendations',
          icon: 'mdi-thumb-up-outline',
          value: 0,
          loading: true,
        },
      ],
      dateOptions: [
        {
          text: 'Today',
          value: 1,
        },
        {
          text: 'Yesterday',
          value: 2,
        },
        {
          text: 'This Week',
          value: 3,
        },
        {
          text: 'Last Week',
          value: 4,
        },
        {
          text: 'This Month',
          value: 5,
        },
        {
          text: 'Last Month',
          value: 6,
        },
        {
          text: 'All Time',
          value: 7,
        },
      ],
      dateSelected: 7,
    }),
    async mounted () {
      await this.fetchData()
    },
    methods: {
      async fetchData () {
        const params = {
          params: {
            date: this.dateSelected,
          },
        }

        this.stats.forEach((value, index) => {
          this.stats[index].loading = true
        })

        const data = await Promise.all([
          this.$repo.statistics
            .count(params)
            .then((r) => r.data.data.total)
            .catch(() => this.handleError()),
          this.$repo.google
            .budgetIncreaseCount(params)
            .then((r) => r.data.data.total)
            .catch(() => this.handleError()),
          this.$repo.google
            .pauseCount(params)
            .then((r) => r.data.data.total)
            .catch(() => this.handleError()),
          this.$repo.client
            .count(params)
            .then((r) => r.data.data.total)
            .catch(() => this.handleError()),
          this.$repo.recommendation
            .count(params)
            .then((r) => r.data.data.total)
            .catch(() => this.handleError()),
        ])

        this.stats.forEach((value, index) => {
          this.stats[index].loading = false
          this.stats[index].value = data[index]
        })
      },
      handleError () {
        return 'Error'
      },
    },
  }
</script>
